<template>
  <IseiVerification tenant-alias="aifintech" financial-model-alias="finexpress">
    <template v-slot:header-image>
      <b-img
        center
        src="/images/finexpres-logo-black.svg"
        style="width: 50%;"
      />
    </template>
    <template v-slot:header-text>
      ТОВ “Фінансова компанія “Фінекспрес”
    </template>
    <template v-slot:system-link>
      <b-card-text>
        Для укладання договору на
        <a
          href="https://finexpress.com.ua/pochaty-spivpratsy.html"
          target="_blank"
          >отримання платіжних послуг</a
        >, просимо пройти електронну ідентифікацію за допомогою підсистеми
        верифікації ІСЕІ
      </b-card-text>
    </template>
  </IseiVerification>
</template>

<script>
import IseiVerification from "@/containers/IseiVerification.vue";
export default {
  name: "Finexpress",
  components: {
    IseiVerification
  }
};
</script>
